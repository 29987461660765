import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./css/index.css";
import { PageContext } from "./page-context";
import { useEffect, useState } from "react";
import Landing from "./pages/Landing";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Plugins from "./pages/Plugins";
import Memberships from "./pages/shop/Memberships";
import Terms from "./pages/legal/Terms";
import Privacy from "./pages/legal/Privacy";
import Refund from "./pages/legal/Refund";
import Copyright from "./pages/legal/Copyright";
import Rules from "./pages/legal/Rules";
import Vulnerabilities from "./pages/legal/Vulernabilities";
import ComingSoon from "./pages/ComingSoon";
import EditPlugins from "./pages/dashboard/EditPlugins";
import { Helmet } from "react-helmet";

function App() {
  const [userData, setUserData] = useState(null);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PageContext.Provider value={{ screenSize, userData, setUserData }}>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="author" content="Lodestone" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#E76C15" />
        <meta name="description" content="Production just got better" />
        <meta property="og:title" content="Lodestone Services" />
        <meta
          property="og:description"
          content="Lodestone is a platform for Minecraft creators to host events, create custom plugins, and design games. Our platform is designed to help creators build, grow, and monetize their communities."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lode.gg/" />
        <title>Lodestone</title>
      </Helmet>
      <Navbar />
      <Router>
        <Switch>
          <Route path={["/"]} exact component={Landing} />
          <Route path={["/events", "/event"]} exact component={ComingSoon} />
          <Route path={["/plugins", "/plugin"]} exact component={Plugins} />

          <Route path={["/dashboard/profile"]} exact component={ComingSoon} />
          <Route
            path={["/dashboard/plugins", "/dashboard/settings"]}
            exact
            component={EditPlugins}
          />
          <Route path={["/dashboard/events"]} exact component={ComingSoon} />
          <Route
            path={["/upgrade", "/memberships"]}
            exact
            component={Memberships}
          />
          <Route
            path={["/legal/terms", "/legal/tos", "/legal/terms-of-service"]}
            exact
            component={Terms}
          />
          <Route
            path={["/legal/privacy", "/legal/privacy-policy"]}
            exact
            component={Privacy}
          />
          <Route
            path={["/legal/refund", "/legal/refund-policy"]}
            exact
            component={Refund}
          />
          <Route path={["/legal/copyright"]} exact component={Copyright} />
          <Route
            path={["/legal/vulnerabilities"]}
            exact
            component={Vulnerabilities}
          />
          <Route
            path={[
              "/legal/rules",
              "/legal/regulations",
              "/legal/rules-regulation",
            ]}
            exact
            component={Rules}
          />
          <Redirect to="/"></Redirect>
        </Switch>
      </Router>
      <Footer />
    </PageContext.Provider>
  );
}

export default App;
