import LegalSidebar from "../../components/LegalSidebar";

export default function Rules() {
  return (
    <div>
            <div className="absolute background-circle min-h-screen top-[-200px] left-12 z-[-1]" />
      <div className="absolute background-circle min-h-screen bottom-[-200px] right-12 z-[-1]" />
      <div className="flex flex-col md:flex-row items-start justify-center">
        <LegalSidebar active="RULES" />
        <div className="md:ml-2 bg-[#3e3e3e] rounded-2xl px-5 py-3 w-full md:w-[50rem]">
          <h1 className="text-3xl inter-bold">Regulations</h1>
          <p className="text-gray-300">Last Modified at 9/12/24 2:39 PM</p>
          <hr className="my-2"></hr>
        </div>
      </div>
    </div>
  );
}
