import LegalSidebar from "../../components/LegalSidebar";

export default function RefundPolicy() {
  return (
    <div>
            <div className="absolute background-circle min-h-screen top-[-200px] left-12 z-[-1]" />
      <div className="absolute background-circle min-h-screen bottom-[-200px] right-12 z-[-1]" />
      <div className="flex flex-row items-start justify-center">
        <LegalSidebar active="REFUND" />
        <div className="ml-2 bg-[#3e3e3e] rounded-2xl px-5 py-3 w-[50rem]">
          <h1 className="text-3xl inter-bold">Refund Policy</h1>
          <p className="text-gray-300">Last Modified at 9/12/24 2:39 PM</p>
          <hr className="my-2"></hr>
          <p>
            At <strong>LODESTONE SERVICES LIMITED LIABILITY COMPANY</strong>{" "}
            ("Lodestone"), we want to ensure that our customers are aware of our
            refund policy before making a purchase. Please note that we do not
            accept any refunds, regardless of the circumstances.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>1. No Refunds</strong>
          </p>
          <p>
            All purchases made through our Website, including subscriptions and
            digital products, are final and non-refundable. This policy applies
            to all transactions without exception.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>2. Understanding Your Purchase</strong>
          </p>
          <p>
            Before completing a purchase, please ensure that you review the
            details carefully. If you have any questions or concerns, contact us
            at business@lodestone.to before making your purchase.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>3. Contact Us</strong>
          </p>
          <p>
            If you have any questions regarding this Refund Policy, please
            contact us at business@lodestone.to. Our team is available to assist you
            with any inquiries you may have before making a purchase.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>4. Changes to the Refund Policy</strong>
          </p>
          <p>
            We may update this Refund Policy at any time. All changes will be
            posted on this page, and your continued use of our services
            signifies your acceptance of the updated policy.
          </p>
        </div>
      </div>
    </div>
  );
}
