import Banner from "../assets/banner.svg";
import { useContext } from "react";
import { PageContext } from "../page-context";

export default function Footer() {
  const { screenSize } = useContext(PageContext);

  return (
    <div>
      {screenSize <= 768 && (
        <div className="space-y-3 items-center justify-center flex flex-col my-4">
          <img src={Banner} alt="lodestone banner" width={160}></img>
          <p className="text-md">© Lodestone Services LLC 2024</p>
        </div>
      )}
      <div className="flex flex-row items-center justify-center space-x-16 my-5">
        {screenSize > 768 && (
          <div className="space-y-3">
            <img src={Banner} alt="lodestone banner" width={160}></img>
            <p className="text-md">© Lodestone Services LLC 2024</p>
          </div>
        )}
        <div className="flex flex-col space-y-3">
          <h1>Company</h1>
          <a
            className="text-gray-300 hover:text-gray-200 transition-colors"
            href="/legal/terms"
          >
            Terms of Service
          </a>
          <a
            className="text-gray-300 hover:text-gray-200 transition-colors"
            href="/legal/privacy"
          >
            Privacy Policy
          </a>
          <a
            className="text-gray-300 hover:text-gray-200 transition-colors"
            href="/legal/refund-policy"
          >
            Refund Policy
          </a>
        </div>
        <div className="flex flex-col space-y-3">
          <h1 className="inter-bold">Resources</h1>
          <a
            className="text-gray-300 hover:text-gray-200 transition-colors"
            href="/discord"
          >
            Support
          </a>
          <a
            className="text-gray-300 hover:text-gray-200 transition-colors"
            href="https://docs.lode.gg/"
          >
            Docs
          </a>
          <a
            className="text-gray-300 hover:text-gray-200 transition-colors"
            href="https://status.lode.gg"
          >
            Status
          </a>
        </div>
        <div className="flex flex-col space-y-3">
          <h1 className="inter-black">Socials</h1>
          <a
            className="text-gray-300 hover:text-gray-200 transition-colors"
            href="/discord"
          >
            Discord
          </a>
          <a
            className="text-gray-300 hover:text-gray-200 transition-colors"
            href="https://x.com/lodeggs"
          >
            X (Twitter)
          </a>
        </div>
      </div>
      <p className="uppercase text-gray-400 text-center text-xs my-5">
        LODESTONE IS NOT AN OFFICIAL MINECRAFT SERVICE. NOR APPROVED BY OR
        ASSOCIATED WITH MOJANG OR MICROSOFT.
      </p>
    </div>
  );
}
