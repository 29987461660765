import LegalSidebar from "../../components/LegalSidebar";

export default function PrivacyPolicy() {
  return (
    <div>
            <div className="absolute background-circle min-h-screen top-[-200px] left-12 z-[-1]" />
      <div className="absolute background-circle min-h-screen bottom-[-200px] right-12 z-[-1]" />
      <div className="flex flex-row items-start justify-center">
        <LegalSidebar active="PRIVACY" />
        <div className="ml-2 bg-[#3e3e3e] rounded-2xl px-5 py-3 w-[50rem]">
          <h1 className="text-3xl inter-bold">Privacy Policy</h1>
          <p className="text-gray-300">Last Modified at 9/12/24 2:39 PM</p>
          <hr className="my-2"></hr>
          <p>
            At <strong>LODESTONE SERVICES LIMITED LIABILITY COMPANY</strong>{" "}
            ("Lodestone", "Lodestone Services", "lode.gg", "lode", "we", "us",
            or "our"), we are committed to protecting your privacy. This Privacy
            Policy outlines how we handle your personal information and ensures
            that all data is securely protected.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>1. Information We Collect</strong>
          </p>
          <p>
            We collect information that you provide to us directly, such as your
            name, email address, and payment details when you make a purchase or
            contact us. We also collect information about your use of our
            Website and services to improve our offerings.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>2. How We Use Your Information</strong>
          </p>
          <p>
            The information we collect is used to:
            <ul>
              <li>- Process and manage your orders and subscriptions.</li>
              <li>
                - Communicate with you about your account, purchases, or updates
                to our services.
              </li>
              <li>
                - Improve our Website and services based on your feedback and
                usage.
              </li>
            </ul>
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>3. Data Security</strong>
          </p>
          <p>
            We employ robust security measures to protect your personal
            information from unauthorized access, disclosure, or misuse. Your
            data is stored securely, and we continuously review and enhance our
            security practices.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>4. No Sale or Sharing of Personal Information</strong>
          </p>
          <p>
            <strong>LODESTONE SERVICES LIMITED LIABILITY COMPANY</strong> does
            not sell, rent, or trade your personal information to third parties.
            We do not share your data with external entities except where
            required by law or as necessary to provide our services to you.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>5. Your Rights and Choices</strong>
          </p>
          <p>
            You have the right to access, update, or delete your personal
            information that we hold. If you wish to exercise any of these
            rights or have any concerns about your data, please contact us at
            business@lodestone.to.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>6. Changes to the Privacy Policy</strong>
          </p>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page, and your continued use of our services
            will signify your acceptance of the revised policy.
          </p>

          <p className="text-2xl mt-5 inter-bold">
            <strong>7. Contact Us</strong>
          </p>
          <p>
            If you have any questions or concerns about our Privacy Policy or
            data practices, please contact us at business@lodestone.to. We are here to
            assist you with any inquiries you may have.
          </p>
        </div>
      </div>
    </div>
  );
}
