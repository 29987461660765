import { BiCopyright, BiMoney } from "react-icons/bi";
import { FaHeart, FaLock, FaScroll } from "react-icons/fa";
import { FaShield } from "react-icons/fa6";

export default function LegalSidebar({ active }) {
  return (
    <div className="bg-[#3e3e3e] px-5 py-3 rounded-2xl w-full md:w-auto mb-2 md:mb-0">
      <div className="space-y-2">
        <h1 className="text-2xl font-extrabold">Legal</h1>
        <div
          className={`${
            active === "TERMS"
              ? "bg-[#686868] hover:bg-[#595959]"
              : "hover:text-white"
          } cursor-pointer transition-all flex flex-row items-center justify-start px-5 py-1 rounded-2xl`}
        >
          <FaHeart size={15} className="mr-2" />
          <a href="/legal/terms">Terms of Service</a>
        </div>
        <div
          className={`${
            active === "RULES"
              ? "bg-[#686868] hover:bg-[#595959]"
              : "hover:text-white"
          } cursor-pointer transition-all flex flex-row items-center justify-start px-5 py-1 rounded-2xl`}
        >
          <FaScroll size={15} className="mr-2" />
          <a href="/legal/rules">Regulations</a>
        </div>
        <div
          className={`${
            active === "COPYRIGHT"
              ? "bg-[#686868] hover:bg-[#595959]"
              : "hover:text-white"
          } cursor-pointer transition-all flex flex-row items-center justify-start px-5 py-1 rounded-2xl`}
        >
          <BiCopyright size={15} className="mr-2" />
          <a href="/legal/copyright">Copyright</a>
        </div>
        <div
          className={`${
            active === "REFUND"
              ? "bg-[#686868] hover:bg-[#595959]"
              : "hover:text-white"
          } cursor-pointer transition-all flex flex-row items-center justify-start px-5 py-1 rounded-2xl`}
        >
          <BiMoney size={15} className="mr-2" />
          <a href="/legal/refund-policy">Refund Policy</a>
        </div>
        <h1 className="text-xl font-extrabold">Privacy</h1>
        <div
          className={`${
            active === "PRIVACY"
              ? "bg-[#686868] hover:bg-[#595959]"
              : "hover:text-white"
          } cursor-pointer transition-all flex flex-row items-center justify-start px-5 py-1 rounded-2xl`}
        >
          <FaLock size={15} className="mr-2" />
          <a href="/legal/privacy">Privacy Policy</a>
        </div>
        <div
          className={`${
            active === "VULNERABILITIES"
              ? "bg-[#686868] hover:bg-[#595959]"
              : "hover:text-white"
          } cursor-pointer transition-all flex flex-row items-center justify-start px-5 py-1 rounded-2xl`}
        >
          <FaShield size={15} className="mr-2" />
          <a href="/legal/vulnerabilities">Vulnerabilities</a>
        </div>
      </div>
    </div>
  );
}
